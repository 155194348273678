import { useCartStore } from "~/store/cart";
import { useCheckoutStore } from "~/store/checkout";
import type CartProduct from "~/types/CartProduct";

export function useCartPrices() {
    const moneyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const COLOR_PRINT = "Color Print + Digital";
    const B_W_PRINT = "B&W Print + Digital";
    const DIGITAL_ONLY = "Digital Only";

    const checkoutStore = useCheckoutStore();
    const config = useRuntimeConfig();

    const cartItems = computed(() => useCartStore().items);
    const loading = computed(() => useCartStore().loading);

    const subtotal = computed(() => useCartStore().summary?.subtotal ?? 0);
    const shippingCost = computed(() => useCartStore().summary?.shipping ?? 0);
    const salesTax = computed(() => useCartStore().summary?.tax ?? 0);
    const total = computed(() => useCartStore().summary?.total ?? 0);
    const qty = computed(() => useCartStore().qty); //ref(0);

    const numOfDigitalWorkbooks = computed((): number => useCartStore().qty);
    const numOfPrintedWorkbooks = computed((): number => {
        let val = 0;
        for (const item of cartItems.value) {
            if (item.order_type === B_W_PRINT) {
                val += item.qty;
            }
        }
        return val;
    });
    const numOfPrintedColorWorkbooks = computed((): number => {
        let val = 0;
        for (const item of cartItems.value) {
            if (item.order_type === COLOR_PRINT) {
                val += item.qty;
            }
        }
        return val;
    });
    const numOfCwCodes = computed(() => useCartStore().qty);

    const userState = computed(() => checkoutStore.shippingAddress.state);
    const userZip = computed(() => checkoutStore.shippingAddress.zip);

    // Functions
    function productPrice(product: CartProduct): number {
        return parseFloat(product.price.toFixed(2));
    }

    // Watch
    watch(userState, async (newVal, oldVal) => {
        // Check loading status to prevent multiple fetches
        if (!loading.value) {
            await useCartStore().fetchCartAndSummary(true, userState.value, userZip.value);
        }
    });
    watch(userZip, async (newVal, oldVal) => {
        if (!loading.value) {
            await useCartStore().fetchCartAndSummary(true, userState.value, userZip.value);
        }
    });
    watch(qty, async (newVal, oldVal) => {
        if (!loading.value) {
            await useCartStore().fetchCartAndSummary(true, userState.value, userZip.value);
        }
    });

    return {
        loading,
        moneyFormatter,
        productPrice,
        subtotal,
        salesTax,
        total,
        qty,
        shippingCost,
        numOfDigitalWorkbooks,
        numOfPrintedWorkbooks,
        numOfPrintedColorWorkbooks,
        numOfCwCodes,
        COLOR_PRINT,
        B_W_PRINT,
        DIGITAL_ONLY,
    };
}
