import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (!process.client) return;

    const { Replay } = await import("@sentry/vue");
    Sentry.addIntegration(
        new Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    );
}

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();
    const browserTracing = new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    });

    return [browserTracing];
}

export default defineNuxtPlugin({
    name: "sentry",
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        if (!config.public.SENTRY_DSN_PUBLIC) {
            return;
        }

        Sentry.init({
            app: vueApp,
            dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
            integrations: getSentryIntegrations(),

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,

            // Only send events to Sentry if the URL matches one of the allowed URLs
            allowUrls: [/americanbookcompany\.com/i, /(.*\.)americanbookcompany\.com/i],
            // Places to ignore errors from (e.g. browser extensions)
            denyUrls: [
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
            ],

            // This option receives a list of strings and regular expressions to match against the error message.
            // When using strings, partial matches will be filtered out. If you need to filter by exact match,
            // use regex patterns instead.
            ignoreErrors: [
                // /^Exact Match Message$/,

                // 419 error due to expired CSRF token
                "Request failed with status code 419",
                // These are common errors when deploying a new version of the site.
                // Chunk assets are not found or failed to import during deployment.
                "Importing a module script failed",
                "Failed to fetch dynamically imported module",
                "error loading dynamically imported module",
                "Unable to preload CSS",
                "Load failed", // Ex: /_nuxt/builds/meta/ed47fd2a-b6a3-4fa0-b522-1e1a7cfd608f.json

                // Ignore all `ResizeObserver loop limit exceeded` errors
                "ResizeObserver loop limit exceeded",

                // Lost connection to the server due to network error
                "Network Error",
                // The request is not allowed by the user agent or the platform in the current context,
                // possibly because the user denied permission.
                "NotAllowedError",
                // CMS is probably down. We should already be alerted by the CMS monitoring.
                "Failed to fetch",
                "FetchError",
                // This error will occur when the request is aborted by the client before reading the body has finished.
                "Request aborted",

                // User get redirect or user navigated away before the request is completed
                "UnhandledRejection",
            ],
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();
    },
});
